import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { CanceledError } from 'axios';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SearchResultsDropdown from './SearchResultsDropdown';
import { ReactComponent as CloseIcon } from 'src/ui/pages/Chat/assets/icons/close.svg';
import cn from 'classnames';

import useDebouncedState from 'src/utils/hooks/useDebouncedState';
import { useAppDispatch } from 'src/store';
import { chatSliceActions } from 'src/ui/pages/Chat/store';
import useSearchParams from 'src/utils/hooks/useSearchParams';
import LottieAnimation from 'src/ui/components/LottieAnimation';
import { t } from 'src/utils/getTranslation';
import type { QuerySearchParamsType } from '../../types/searchTypes';
import type { IChannel, IMessage, IUser } from 'src/types';
import searchUtils from '../../utils/searchUtils';
import useSignal from 'src/utils/hooks/useSignal';
import { closeSwipableChatSidebarEvent } from '../ChatSidebar/SidebarDrawer';

type PropsType = {
  className?: string;
  noneAutoFocus?: boolean;
  inputClassName?: string;
  inputContainerClassName?: string;
  isFocused?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;

  searchResults?: {
    className?: string;
    resultsWrapperClassName?: string;
  };
};

const Search: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();
  const [params, setParam] = useSearchParams<QuerySearchParamsType>();
  const { chatId } = useParams();
  const search = useDebouncedState(params.search || '', (newValue) => setParam('search', newValue?.trim()), 500);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = React.useState(false);
  const [usersList, setUsersList] = React.useState<IUser[]>([]);
  const [channelsList, setChannelsList] = React.useState<IChannel[]>([]);
  const [messagesList, setMessagesList] = React.useState<IMessage[]>([]);
  const inputId = 'search-input';
  const { handleRequestWithSignal } = useSignal();

  const searchString = params.search
    ? params.search.replace(/[<]/g, '&lt;').replace(/[>]/g, '&gt;')?.trim()
    : '';

  React.useEffect(() => {
    if (!isSearchDropdownOpen || !searchString) {
      return;
    }
    (async () => {
      try {
        setIsLoading(true);
        const data = await handleRequestWithSignal((signal) => searchUtils.getSearchResults(search.value, { signal }));
        setUsersList(data.users);
        setChannelsList(data.channels);
        setMessagesList(data.messages);
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }
        toast.error(t('errors:searchData.failed'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [handleRequestWithSignal, isSearchDropdownOpen, search.value, searchString]);

  const userData = React.useMemo(() => {
    return {
      list: usersList.slice(-3),
      isMoreButtonVisible: Boolean(usersList.length > 3),
    };
  }, [usersList]);

  const channelData = React.useMemo(() => {
    return {
      list: channelsList.slice(-3),
      isMoreButtonVisible: Boolean(channelsList.length > 3),
    };
  }, [channelsList]);

  const messageData = React.useMemo(() => {
    return {
      list: messagesList.slice(-3),
      isMoreButtonVisible: Boolean(messagesList.length > 3),
    };
  }, [messagesList]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    search.set(ev.target.value);
    if (ev.target.value.length > 0 && chatId !== 'search') {
      setIsSearchDropdownOpen(true);
    } else if (!ev.target.value.length) {
      setIsSearchDropdownOpen(false);
    }
  };

  const handleClickClose = () => {
    search.set('');
    setIsSearchDropdownOpen(false);

    dispatch(chatSliceActions.setIsActiveSearchInput(false));
  };

  const handleCloseResults = () => {
    setIsSearchDropdownOpen(false);
    closeSwipableChatSidebarEvent();
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLElement | null;
    if (target?.id === inputId) {
      return;
    }
    handleCloseResults();
  };

  return (
    <StyledSearch className={props.className}>
      <TextField
        value={search.value}
        onChange={handleChange}
        fullWidth
        margin="dense"
        autoFocus={!props.noneAutoFocus}
        id={inputId}
        classes={{
          root: 'search-input__container',
        }}
        InputProps={{
          inputRef: props.inputRef,
          endAdornment: (
            <IconButton
              onClick={handleClickClose}
              className="search-input__close-button"
            >
              {isLoading ? (
                <LottieAnimation animation="circleLoader" className="button__loader-icon" />
              ) : (
                search.value && <CloseIcon />
              )}
            </IconButton>
          ),
          classes: {
            root: cn('search-input__container', props.inputContainerClassName),
            input: cn('search-input__input', props.inputClassName),
            focused: 'search-input__focused',
            notchedOutline: 'search-input__outline',
          },
        }}
        inputProps={{
          autoComplete: 'on',
          placeholder: t('chat:navigation.search.searchPlaceholder'),
        }}
      />
      {isSearchDropdownOpen && (
        <ClickAwayListener onClickAway={handleClickAway}
        >
          <div>
            <SearchResultsDropdown
              channelData={channelData}
              messageData={messageData}
              userData={userData}
              handleClose={handleCloseResults}
              resultsWrapperClassName={props.searchResults?.resultsWrapperClassName}
              className={props.searchResults?.className}
            />
          </div>
        </ClickAwayListener>
      )}
    </StyledSearch >
  );
};

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  .search-input__container, .search-input__adornment, .search-input__adornment {
    font-size: ${({ theme }) => theme.font.size.sm};
  }

  .search-input__container {
    background-color: ${({ theme }) => theme.palette.background.default};
    margin: 0px;
    border-width: 1px;
    border-radius: 6px;
  }
  
  .search-input__input {
    padding: 9px 16px;
  }

  .search-input__close-button {
    padding: 0;
  }
  
  .search-input__focused {
    border: none;

    .search-input__outline {
      border: 1px solid ${({ theme }) => (theme.palette.input.border.focused)} !important;
    }
  }
  
  .search-input__outline {
    border: 1px solid ${({ theme }) => theme.palette.input.border.default} !important;
  }

  .button__loader-icon {
    height: 1em;
    width: 1em;
  }
`;

export default Search;
