import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/store';
import applyDeclension from 'src/utils/applyDeclension';
import useDebouncedState from 'src/utils/hooks/useDebouncedState';
import chatApi from 'src/api/chatApi';
import { t, language } from 'src/utils/getTranslation';
import { chatSliceV2Actions } from '../../storeV2/chatSliceV2';
import { ROUTES } from 'src/utils/constants';
import { useUser } from 'src/utils/hooks/general';
import type { ChannelType, IChannel } from 'src/types';
import { closeSwipableChatSidebarEvent } from '../ChatSidebar/SidebarDrawer';

export type ExtendedChannelType = (ChannelType & { isMember?: boolean; isOwner?: boolean; membersCount: number });

const getSuffixesByNumber = (count: number) => {
  const channelSuffixes = language === 'ru'
    ? applyDeclension(count, ['', 'а', 'ов'], 'ru')
    : applyDeclension(count, ['', 's', 's'], 'en');

  return channelSuffixes;
};

export const useChannelsOverview = (options: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const [value, setValue] = React.useState('');
  const [channelList, setChannelList] = React.useState<ExtendedChannelType[]>([]);
  const [channelListTotalCount, setChannelListTotalCount] = React.useState(0);
  const [isChannelListLoading, setIsChannelListLoading] = React.useState(false);
  const [isCreateChannelFormOpen, setIsCreateChannelFormOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useUser();

  const search = useDebouncedState(value, (newValue) => setValue(newValue), 300);

  const formatChannelsByPriority = (channels: IChannel[], userId: number, mainChannelId: number) => {
    const channelDataObject = {
      channelsOwner: [],
      channelsMember: [],
      otherPublicChannels: [],
    } as {
      channelsOwner: ExtendedChannelType[];
      channelsMember: ExtendedChannelType[];
      otherPublicChannels: ExtendedChannelType[];
    };

    let mainChannel: ExtendedChannelType;
    channels.forEach((channel) => {
      const formattedChannel = {
        ...channel,
        isMember: Boolean(channel.userToChannels?.find(
          (item) => item.user?.userId === userId,
        )),
        isOwner: userId === channel.ownerId,
        membersCount: channel.userToChannels?.length || 0,
      };

      if (formattedChannel.channelId === mainChannelId) {
        mainChannel = formattedChannel;
        return;
      }

      if (formattedChannel.isOwner) {
        channelDataObject.channelsOwner.push(formattedChannel);
      } else if (formattedChannel.isMember) {
        channelDataObject.channelsMember.push(formattedChannel);
      } else {
        channelDataObject.otherPublicChannels.push(formattedChannel);
      }
    });

    const formattedChannelList = [
      ...channelDataObject.channelsOwner,
      ...channelDataObject.channelsMember,
      ...channelDataObject.otherPublicChannels,
    ];

    if (mainChannel!) {
      formattedChannelList.unshift(mainChannel);
    }

    return formattedChannelList;
  };

  const mainChannelId = useAppSelector(({ main }) => main.user!.company!.mainChannelId);

  const getChannelList = React.useCallback(async () => {
    try {
      setIsChannelListLoading(true);
      const { data } = await chatApi.getChannelSearchResults({
        search: search.debouncedValue,
      });

      const formattedChannelList = formatChannelsByPriority(data.payload, user?.userId, mainChannelId);

      setChannelList(formattedChannelList);
      setChannelListTotalCount(formattedChannelList.length);
    } catch (error) {
      toast.error(t('errors:chat.getChannels.failed'));
    } finally {
      setIsChannelListLoading(false);
    }
  }, [search.debouncedValue, user?.userId, mainChannelId]);

  React.useEffect(() => {
    if (!options?.isOpen) {
      return;
    }

    getChannelList();
  }, [getChannelList, options?.isOpen]);

  const handleSearchStringChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    search.set(ev.target.value);
  };

  const handleCreateChannelClick = () => {
    setIsCreateChannelFormOpen(true);
    search.set('');
  };

  const onModalClose = () => {
    options.handleClose();
    search.set('');
  };

  const navigateToChannel = (event: React.MouseEvent, channelId: number) => {
    event.stopPropagation();

    navigate(ROUTES.chat.createPath(channelId));
    options.handleClose();

    closeSwipableChatSidebarEvent();
  };

  const handleJoinClick = async (event: React.MouseEvent, channel: ExtendedChannelType) => {
    event.stopPropagation();
    await chatApi.joinToChannel(channel.channelId);
    dispatch(chatSliceV2Actions.handleNewChannel({ channel }));
    setChannelList((prev) => prev.map((prevChannel) => (
      prevChannel.channelId === channel.channelId
        ? { ...prevChannel, isMember: true, membersCount: prevChannel.membersCount + 1 }
        : prevChannel
    )));
  };

  const handleSubmitLeaveChannel = async (channelId: number) => {
    await chatApi.leaveChannel(channelId);
    dispatch(chatSliceV2Actions.handleChannelDelete({ channelId }));
    setChannelList((prev) => prev.map((channel) => (
      channel.channelId === channelId
        ? { ...channel, isMember: false, membersCount: channel.membersCount - 1 }
        : channel
    )));
  };

  const handleCloseCreateChannelForm = () => {
    setIsCreateChannelFormOpen(false);
  };

  return {
    channelList,
    channelListTotalCount,
    isChannelListLoading,
    searchValue: search.value,
    isCreateChannelFormOpen,
    onModalClose,
    handleCreateChannelClick,
    handleSearchStringChange,
    handleSubmitLeaveChannel,
    handleJoinClick,
    navigateToChannel,
    getSuffixesByNumber,
    handleCloseCreateChannelForm,
    getChannelList,
  };
};

export const useChannelLink = (options: {
  handleJoinClick: (event: React.MouseEvent, channel: ExtendedChannelType) => Promise<void>;
  handleSubmitLeaveChannel: (channelId: number) => Promise<void>;
}) => {
  const [isLeaveChannelConfirmationOpen, setIsLeaveChannelConfirmationOpen] = React.useState(false);
  const [isLeaveChannelLoading, setIsLeaveChannelLoading] = React.useState(false);
  const [isJoinChannelLoading, setIsJoinChannelLoading] = React.useState(false);

  const handleCancelLeaveChannel = () => {
    if (isLeaveChannelLoading) {
      return;
    }
    setIsLeaveChannelConfirmationOpen(false);
  };

  const handleLeaveChannelClick = (event: React.MouseEvent, channel: ExtendedChannelType) => {
    event.stopPropagation();
    if (channel.isOwner) {
      return;
    }
    setIsLeaveChannelConfirmationOpen(true);
  };

  const handleCloseLeaveChannelConfirm = () => {
    setIsLeaveChannelConfirmationOpen(false);
  };

  const handleJoinClick = async (event: React.MouseEvent, channel: ExtendedChannelType) => {
    try {
      setIsJoinChannelLoading(true);
      await options.handleJoinClick(event, channel);
    } catch (error) {
      toast.error(t('chat:channels.addingUser.error'));
    } finally {
      setIsJoinChannelLoading(false);
    }
  };

  const handleSubmitLeaveChannel = async (channelId: number) => {
    try {
      setIsLeaveChannelLoading(true);
      await options.handleSubmitLeaveChannel(channelId);
      toast.success(t('chat:channels.changesSaved'));
    } catch {
      toast.error(t('chat:channels.leaveChannel.error'));
    } finally {
      setIsLeaveChannelLoading(false);
      setIsLeaveChannelConfirmationOpen(false);
    }
  };

  return {
    isLeaveChannelConfirmationOpen,
    isLeaveChannelLoading,
    isJoinChannelLoading,
    handleCloseLeaveChannelConfirm,
    handleCancelLeaveChannel,
    handleSubmitLeaveChannel,
    handleLeaveChannelClick,
    handleJoinClick,
  };
};
