import chatApi from 'src/api/chatApi';
import type { GetParamsType } from 'src/api/http/http.types';
import type { IChannel, IMessage, IUser } from 'src/types';

const getSearchResults = async (search?: string, params?: { signal?: AbortSignal }) => {
  const usersPromise = chatApi.getUserSearchResults({ search }, params);
  const channelsPromise = chatApi.getChannelSearchResults({ search, withPagination: true }, params);
  const messagesPromise = chatApi.getMessageSearchResults({ search }, params);
  const promises = [usersPromise, channelsPromise, messagesPromise];

  const [
    usersResponse,
    channelsResponse,
    messagesResponse,
  ] = await Promise.all(promises);

  const searchResults = {
    users: usersResponse.data.payload as IUser[],
    usersCount: usersResponse.data.meta.totalRecords,
    channels: channelsResponse.data.payload as IChannel[],
    channelsCount: channelsResponse.data.meta.totalRecords,
    messages: messagesResponse.data.payload as IMessage[],
    messagesCount: messagesResponse.data.meta.totalRecords,
  };

  return searchResults;
};

const getChannelsSearchResults = (async (
  params: GetParamsType & {
    withPagination?: boolean;
    showMyChannels?: boolean;
  },
) => {
  const response = await chatApi.getChannelSearchResults(params);

  const channelsSearchResults = {
    channels: response.data.payload,
    channelsCount: response.data.meta.totalRecords,
  };

  return channelsSearchResults;
});

const getMessagesSearchResults = async (params: GetParamsType) => {
  const response = await chatApi.getMessageSearchResults(params);

  const messagesSearchResults = {
    messages: response.data.payload,
    messagesCount: response.data.meta.totalRecords,
  };

  return messagesSearchResults;
};

const getUsersSearchResults = async (params: GetParamsType) => {
  const response = await chatApi.getUserSearchResults(params);

  const userSearchResults = {
    users: response.data.payload,
    usersCount: response.data.meta.totalRecords,
  };

  return userSearchResults;
};

export default {
  getSearchResults,
  getChannelsSearchResults,
  getMessagesSearchResults,
  getUsersSearchResults,
};
