import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';

import storage from 'src/utils/storage';

import ListItem from '@mui/material/ListItem';
import ChannelsList from './ChannelsList';
import ChatListHeader from './ChatListHeader';
import { ReactComponent as PackageFilledIcon } from '../../assets/icons/package-filled.svg';
import { ReactComponent as LayoutGridIcon } from '../../assets/icons/layout-grid.svg';
import { ReactComponent as LogOutIcon } from '../../assets/icons/log-out.svg'; // ???
import { ReactComponent as RemoveMemberIcon } from '../../assets/icons/remove-member.svg';
import ChannelsOverview from '../ChannelsOverview';

import { useChannelsBlockData } from './ChannelsBlock.hooks';
import ThreadNavItem from '../SidebarActionItems/ThreadNavItem';

const ChannelsBlock: React.FC<{
  onLinkClick?: () => void;
}> = (props) => {
  const { t } = useTranslation('chat');

  const {
    channels,
    dmChannels,
    archivedChannels,
    isChannelsLoading,
    handleNewChannelClick,
    handleNewDmClick,
    isArchiveOpen,
    isChannelOverviewOpen,
    toggleArchiveView,
    toggleChannelOverview,
    handleUnarchiveallClick,
    handleDeleteAllArchivedClick,
    handleChannelClick,
  } = useChannelsBlockData(props.onLinkClick);

  const handleClick: typeof handleChannelClick = (...args) => {
    props.onLinkClick?.();
    handleChannelClick(...args);
  };

  if (isArchiveOpen) {
    return (
      <StyledChannelsBlock key={1}>
        <ChannelsList
          isLoading={isChannelsLoading}
          renderHeader={() => (
            <ListItem className="channels-list-header">
              <ChatListHeader
                showBackButton
                onBackButtonClick={toggleArchiveView}
                title={t('navigation.archivalChannels')}
                showMoreButton
                moreMenuItems={[
                  {
                    title: t('channels.menu.restoreAll'),
                    onClick: ({ onClose }) => {
                      handleUnarchiveallClick();
                      onClose();
                    },
                    id: uuidv4(),
                    icon: <LogOutIcon className="unarchive-icon" />,
                  },
                  {
                    title: t('channels.menu.deleteAll'),
                    onClick: ({ onClose }) => {
                      handleDeleteAllArchivedClick();
                      onClose();
                    },
                    id: uuidv4(),
                    icon: <RemoveMemberIcon />,
                  },
                ]}
              />
            </ListItem>
          )}
          onChannelClick={handleClick}
          channelsList={archivedChannels}
        />
      </StyledChannelsBlock>
    );
  }

  return (
    <StyledChannelsBlock key={2}>
      <ThreadNavItem isLoading={isChannelsLoading} />
      <ChannelsList
        isLoading={isChannelsLoading}
        isInitiallyOpened={storage.isChannelListOpened.get() ?? true}
        handleOpenStateChange={storage.isChannelListOpened.set}
        renderHeader={({ isOpen, toggleCollapse }) => (
          <ListItem className="channels-list-header">
            <ChatListHeader
              title={t('navigation.channels')}
              isCollapseOpen={isOpen}
              onCollapseTogglerClick={toggleCollapse}
              showCollapseButton
              showMoreButton
              moreMenuItems={[
                {
                  title: t('channels.menu.channelOverview'),
                  onClick: ({ onClose }) => {
                    toggleChannelOverview();
                    onClose();
                  },
                  id: uuidv4(),
                  icon: <LayoutGridIcon />,
                },
                {
                  title: `${t('navigation.archivalChannels')} (${archivedChannels.length})`,
                  onClick: toggleArchiveView,
                  id: uuidv4(),
                  hidden: !archivedChannels.length,
                  icon: <PackageFilledIcon />,
                },
              ]}
              onPlusIconClick={handleNewChannelClick}
              plusIconTooltipText={t('navigation.createChannelTooltip')}
              showPlusButton
            />
          </ListItem>
        )}
        onChannelClick={handleClick}
        channelsList={channels}
      />

      <ChannelsList
        isLoading={isChannelsLoading}
        isInitiallyOpened={storage.isDMListOpened.get() ?? true}
        handleOpenStateChange={storage.isDMListOpened.set}
        renderHeader={({ isOpen, toggleCollapse }) => (
          <ListItem className="channels-list-header">
            <ChatListHeader
              title={t('navigation.privateMessages')}
              isCollapseOpen={isOpen}
              onCollapseTogglerClick={toggleCollapse}
              showCollapseButton
              onPlusIconClick={handleNewDmClick}
              plusIconTooltipText={t('navigation.createMessageTooltip')}
              showPlusButton
            />
          </ListItem>
        )}
        onChannelClick={handleClick}
        channelsList={dmChannels}
      />

      <ChannelsOverview isOpen={isChannelOverviewOpen} onClose={toggleChannelOverview} />
    </StyledChannelsBlock>
  );
};

export const StyledChannelsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .channels-list-header {
    padding: 6px 16px;
  }
`;

export default ChannelsBlock;
