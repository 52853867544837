import styled from 'styled-components';

const StyledResultsCategory = styled.div`
  padding: 10px 16px;
  max-width: inherit;
  white-space: break-spaces;
  padding-right: 5px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    padding-right: 5px;
  }

 .results__title {
    font-weight: ${({ theme }) => theme.font.weight.lg};
    font-size: ${({ theme }) => theme.font.size.md};
    padding-left: 4px;
  }

  .results__more-button {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
    padding: 8px 4px;
    color: ${({ theme }) => theme.palette.primary[500]};
    stroke: ${({ theme }) => theme.palette.primary[500]};
  }

  .more-button__text {
    font-weight: ${({ theme }) => theme.font.weight.lg};
  }

  .channel-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
  }

  .results__channels {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 5px;
    padding: 7px 4px;
  }

  .results__channels,.results__user,.results__message {
    &:hover {
      background: ${({ theme }) => theme.palette.select.optionBackground.primary};
      border-radius: 0;
      cursor: pointer;
    }
  }

  .results__text {
    height: 18px;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: ${({ theme }) => theme.font.weight.md};
    font-size: ${({ theme }) => theme.font.size.sm};
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .results__message {
    padding: 6px 4px;
  }

  .results__message-title {
    font-weight: ${({ theme }) => theme.font.weight.lg};
    margin-bottom: 4px;
  }

  .results__message-text {
    font-weight: ${({ theme }) => theme.font.weight.md};
    color: ${({ theme }) => theme.palette.text.secondary};
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .results__message-item {
    scrollbar-width: none;
  }

  .results__empty-state {
    padding-left: 4px;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.font.size.xs};
    font-weight: ${({ theme }) => theme.font.weight.sm};
  }

  mark {
    background-color: ${({ theme }) => theme.palette.primary[200]};
  }
`;

export default StyledResultsCategory;
