import type { UserToChannelsType } from 'src/types/chatTypes';
import type { IUser } from 'src/types/userTypes';

export default (data: {
  userToChannelsOrigin?: UserToChannelsType[];
}) => {
  const users = {} as Record<string, IUser>;
  const usersToChannel = [] as (Omit<UserToChannelsType, 'user'>)[];

  data.userToChannelsOrigin!.forEach((userToChannel) => {
    const { user, ...formatted } = { ...userToChannel };
    if (userToChannel.userId && user) {
      users[userToChannel.userId] = user;
    }

    usersToChannel.push(formatted);
  });

  return {
    users,
    usersToChannel,
  };
};
