export enum SocketEventsENUM {
  connect = 'connect',
  disconnect = 'disconnect',
  error = 'error',
  connectError = 'connect_error',
  forceLogout = 'forceLogout',
  customError = 'SEND_ERROR', // 😿😿😿
  userActivityUpdate = 'USER_ACTIVITY_UPDATE',
  getOnlineUsers = 'GET_ONLINE_USERS',
  newUserOnline = 'NEW_USER_ONLINE',
  newUserOffline = 'NEW_USER_OFFLINE',

  chat_getMyChannels = 'CHAT__GET_MY_CHANNELS',
  chat_readMessage = 'CHAT__READ_MESSAGE',
  chat_unReadMessage = 'CHAT__UN_READ_MESSAGE',
  chat_getChannel = 'CHAT__GET_CHANNEL',
  chat_newMention = 'CHAT__NEW_MENTION',
  chat_removedMention = 'CHAT__REMOVED_MENTION',
  chat_userEntranceStatus = 'CHAT__USER_ENTRANCE_STATUS',
  chat_getChannelMessages = 'CHAT__GET_CHANNEL_MESSAGES',
  chat_getThreadMessages = 'CHAT__GET_THREAD_MESSAGES',
  chat_createNewMessage = 'CHAT__CREATE_NEW_MESSAGE',
  chat_newMessage = 'CHAT__NEW_MESSAGE',
  chat_messagesHasBeenRead = 'CHAT__MESSAGES_HAS_BEEN_READ',
  chat_toggleReaction = 'CHAT__TOGGLE_REACTION',
  chat_reactionUpdated = 'CHAT__REACTION_UPDATED',
  chat_channelBecomeVisible = 'CHAT__CHANNEL_BECOME_VISIBLE',

  company_imageUpdated = 'COMPANY__IMAGE_UPDATED',

  action_desktopNotification = 'ACTION__DESKTOP_NOTIFICATION',
  action_crossWorkspaceNotifier = 'ACTION__CROSS_WORKSPACE_NOTIFIER',

  // Depricated events
  getNewMessage = 'GET_NEW_MESSAGE',
  mentionInChannel = 'MENTION_IN_CHANNEL',
  updatedChannel = 'UPDATED_CHANNEL',
  archivedChannel = 'ARCHIVED_CHANNEL',
  unarchivedChannel = 'UNARCHIVED_CHANNEL',
  addUsersToChannel = 'ADD_USERS_TO_CHANNEL',
  removeUserFromChannel = 'REMOVE_USER_FROM_CHANNEL',
  deletedChannel = 'DELETED_CHANNEL',
  ownerReassigned = 'OWNER_REASSIGNED',
  messageDeleted = 'MESSAGE_DELETED',
  joinRoom = 'JOIN_ROOM',
  getUpdatedMessage = 'GET_UPDATED_MESSAGE',
  userTyping = 'USER_TYPING',
  newActionMessage = 'NEW_ACTION_MESSAGE',
}

export default SocketEventsENUM;
