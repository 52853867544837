import React from 'react';
import DescriptionIcon from '@mui/icons-material/Description';

import { DEFAULT_ICON_TEXT } from '../../constants';

import { sortByStrings } from 'src/utils/stringFormattingUtils';
import { useUser } from 'src/utils/hooks/general';
import { t } from 'src/utils/getTranslation';

import GroupItemIcon from './GroupItemIcon';

import { ChatTypesENUM, type IChannel } from 'src/types/chatTypes';
import type { IUser } from 'src/types/userTypes';
import DirectItemIcon from './DirectItemIcon';

const useSidebarChannelItemContent = (params: {
  channel: IChannel;
  iconClassName?: string;
}) => {
  const currentUser = useUser();

  const groupUsers = React.useMemo(() => {
    if (params.channel.type !== ChatTypesENUM.group || !params.channel.userToChannels) {
      return { title: '', list: [] };
    }

    const groupUsersList = params.channel.userToChannels?.reduce<IUser[]>((acc, { user }) => {
      if (user!.userId !== currentUser.userId) {
        acc.push(user!);
      }
      return acc;
    }, [])
      .sort((a, b) => sortByStrings(a?.fullName || '', b?.fullName || ''));

    return {
      title: groupUsersList
        .map((user) => user.fullName!)
        .join(', '),
      list: groupUsersList,
    };
  }, [params.channel.userToChannels, currentUser]);

  const directUser = React.useMemo(() => {
    if (params.channel.userToChannels && params.channel.userToChannels?.length > 1) {
      return params.channel.userToChannels.find(({ user }) => {
        return user?.userId !== currentUser.userId;
      })?.user;
    }
  }, [currentUser, params.channel.userToChannels]);

  if (params.channel.type === ChatTypesENUM.group) {
    return {
      title: groupUsers.title,
      icon: <GroupItemIcon
        userToShowAsPreview={groupUsers.list[0]}
        count={groupUsers.list.length}
      />,
      type: 'group',
    };
  }

  if (params.channel.type === ChatTypesENUM.direct && directUser) {
    return {
      title: directUser.fullName!,
      icon: <DirectItemIcon directUser={directUser} iconClassName={params.iconClassName} />,
      type: 'direct',
    };
  }

  if (params.channel.type === ChatTypesENUM.direct && !directUser) {
    return {
      title: t('chat:notesChannelName'),
      icon: <DescriptionIcon className="notes-icon" />,
      type: 'notes',
    };
  }

  return {
    title: params.channel.name as React.ReactNode,
    icon: params.channel.icon || DEFAULT_ICON_TEXT,
    type: 'default',
  };
};

export default useSidebarChannelItemContent;
