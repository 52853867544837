import type { IUser } from 'src/types';

const getFullName = (user: IUser, withPatronymic?: boolean) => {
  const fullName = user.fullName || `${user.firstName} ${user.lastName}`;
  if (!withPatronymic) {
    return fullName;
  }
  return `${fullName} ${user.patronymic || ''}`;
};

export default getFullName;
