import React from 'react';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import userApi from 'src/api/userApi';
import { chatSliceV2Actions } from 'src/ui/pages/Chat/storeV2/chatSliceV2';
import type { ChannelNotificationStatusENUM } from 'src/types/userTypes';
import { useUser } from './general';
import { useCurrentChannel } from 'src/ui/pages/Chat/Chat.hooks';

type ParamsType = {
  withDataFetching?: boolean;
};

const useChannelNotificationStatus = (params?: ParamsType) => {
  const user = useUser();
  const currentChannel = useCurrentChannel();
  const dispatch = useAppDispatch();
  const channelNotificationStatuses = useAppSelector((store) => store.chatPageV2.channelNotificationStatuses);
  const channels = useAppSelector((store) => store.chatPageV2.channels);
  const dmChannels = useAppSelector((store) => store.chatPageV2.dmChannels);
  const archivedChannels = useAppSelector((store) => store.chatPageV2.archivedChannels);

  const getChannelNotificationStatusById = (channelId: number) => {
    const result = channelNotificationStatuses.find((channelNotificationsStatus) => {
      return channelNotificationsStatus.channelId === channelId;
    })?.notificationStatus;

    return result;
  };

  const currentNotificationsStatus = React.useMemo(() => {
    if (!currentChannel?.channelId) return;
    return getChannelNotificationStatusById(currentChannel.channelId);
  }, [currentChannel, channelNotificationStatuses]);

  React.useEffect(() => {
    if (user?.userId && params?.withDataFetching) {
      getChannelNotificationStatuses();
    }
  }, [
    params?.withDataFetching,
    user?.userId,
    channels,
    dmChannels,
    archivedChannels,
  ]);

  const getChannelNotificationStatuses = async () => {
    try {
      const response = await userApi.getChannelNotificationStatuses();
      dispatch(chatSliceV2Actions.setChannelNotificationStatuses(response.data.payload));
    } catch (err) {
      console.error(err);
    }
  };

  const updateChannelNotificationStatus = async (notificationStatus: ChannelNotificationStatusENUM) => {
    if (!currentChannel?.channelId) return;

    const savedChannelNotificationStatuses = [...channelNotificationStatuses];
    const updatedChannelNotificationStatuses = channelNotificationStatuses.map((channel) => {
      if (channel.channelId === currentChannel.channelId) {
        return {
          ...channel,
          notificationStatus,
        };
      }
      return channel;
    });

    dispatch(chatSliceV2Actions.setChannelNotificationStatuses(updatedChannelNotificationStatuses));

    try {
      await userApi.updateChannelNotificationStatus({
        channelId: currentChannel.channelId,
        notificationStatus,
      });
    } catch (err) {
      dispatch(chatSliceV2Actions.setChannelNotificationStatuses(savedChannelNotificationStatuses));
    }
  };

  return {
    currentNotificationsStatus,
    allChannelNotificationStatuses: channelNotificationStatuses,
    getChannelNotificationStatusById,
    updateChannelNotificationStatus,
    getChannelNotificationStatuses,
  };
};

export default useChannelNotificationStatus;
