import type { IMessage } from 'src/types';
import type { IChildMessagesShortDetails } from '../types';

export default (message: IMessage): IChildMessagesShortDetails => {
  return {
    messageId: message.messageId,
    createdAt: message.createdAt,
    author: {
      userId: message.author.userId,
      firstName: message.author.firstName,
      lastName: message.author.lastName,
      fullName: message.author.fullName,
      workingFrom: message.author.workingFrom,
      avatarMediaItem: message.author.avatarMediaItem,
    },
  };
};
