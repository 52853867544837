const sortByStrings = (a:string, b:string) => {
  const regexCyrillic = /^[а-я]/i;
  const regexLatin = /^[a-z]/i;
  const regexDigits = /^[0-9]/;

  const nameA = a.toLowerCase();
  const nameB = b.toLowerCase();

  if (regexCyrillic.test(nameA) && !regexCyrillic.test(nameB)) {
    return -1;
  }
  if (!regexCyrillic.test(nameA) && regexCyrillic.test(nameB)) {
    return 1;
  }
  if (regexLatin.test(nameA) && !regexLatin.test(nameB)) {
    return -1;
  }
  if (!regexLatin.test(nameA) && regexLatin.test(nameB)) {
    return 1;
  }
  if (regexDigits.test(nameA) && !regexDigits.test(nameB)) {
    return 1;
  }
  if (!regexDigits.test(nameA) && regexDigits.test(nameB)) {
    return -1;
  }

  return nameA.localeCompare(nameB);
};

export {
  sortByStrings,
};
