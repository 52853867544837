import React from 'react';

import CustomEventCreator from 'src/utils/CustomEventCreator';

const userDrawerEvent = new CustomEventCreator<{
  userId: number | null;
}>({ eventName: 'USER_PROFILE_DRAWER' });

export const useUserProfileDrawerData = (props: {
  onOpen?: (userId: number) => void;
  onClose?: () => void;
}) => {
  const [userId, setUserId] = React.useState<number | null>(null);
  const propsRef = React.useRef(props);
  propsRef.current = props;

  React.useEffect(() => {
    return userDrawerEvent.subscribe((data) => {
      if (data.userId) {
        propsRef.current.onOpen?.(data.userId);
      } else {
        propsRef.current.onClose?.();
      }

      setUserId(data.userId);
    });
  }, []);

  return {
    userId,
  };
};

export const openDrawer = (userId: number) => {
  userDrawerEvent.dispatch({ userId });
};

export const closeDrawer = () => {
  userDrawerEvent.dispatch({ userId: null });
};
