import { toast } from 'react-toastify';
import mediaApi from 'src/api/mediaApi';
import type { IMedia } from 'src/types';
import type { MediaLocalType, MediaInfoType } from 'src/types/chatTypes';
import errorCodes from './errorCodes';
import { t } from './getTranslation';

export const convertFileToBase64 = (file: File) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
  });
};

export const convertFileListToBase64 = async (fileList: File[]) => {
  const newFileList = fileList.map(async (file) => {
    if (typeof file !== 'string') {
      const result = await convertFileToBase64(file);
      return result;
    }
    return file;
  });

  return Promise.all(newFileList);
};

export const excludeFoldersFromFileList = async (fileList: FileList) => {
  const fileArray = Array.from(fileList);
  const boolArray = await Promise.all(fileArray.map((file) => checkIsFile(file)));

  const result: File[] = [];
  for (let i = 0; i < boolArray.length; ++i) {
    if (boolArray[i]) {
      result.push(fileArray[i]);
    }
  }
  return result;
};

export const checkIsFile = async (file: File) => {
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.onloadend = (e) => {
      if (e.target?.readyState === FileReader.DONE) {
        const bytesArrayOfFile = new Uint8Array(e.target?.result as ArrayBufferLike);
        const fileLength = bytesArrayOfFile.byteLength;
        if (fileLength) {
          resolve(true);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    };

    const blob = file.slice(0, 5);
    reader.readAsArrayBuffer(blob);
  });
};

export const getMediaItemsFromMediaInfo = (mediaInfo: MediaInfoType | null) => {
  const mediaIds: number[] = [];
  const mediaItemList: IMedia[] = [];
  const mediaLocalItemList: MediaLocalType[] = [];
  if (!mediaInfo) {
    return {
      mediaIds: [],
      mediaList: [],
    };
  }
  const list = Object.values(mediaInfo);
  list.forEach((item) => {
    if (item.mediaItem) {
      mediaIds.push(item.mediaItem.mediaItemId);
      mediaItemList.push(item.mediaItem);
    } else {
      mediaLocalItemList.push({ ...item, isUploaded: false });
    }
  });
  return {
    mediaIds,
    mediaList: mediaItemList,
    mediaLocalItemList,
  };
};

export const handleDownload = async (
  mediaItemId: number,
  name: string,
) => {
  try {
    const response = await mediaApi.getDownloadLink(
      mediaItemId,
    );

    const resp = await fetch(response.data.payload);
    const blob = await resp.blob();

    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (err) {
    toast.error(t(errorCodes.chat.loadingFilesError));
  }
};
