import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/store';
import { chatSliceActions } from '../../store/Chat.reducer';
import chatSelectoresV2 from '../../storeV2/chatSelectorsV2';
import { useCurrentChannel } from '../../Chat.hooks';
import { ChatTypesENUM, type IChannel } from 'src/types';
import { ROUTES } from 'src/utils/constants';
import { useUser } from 'src/utils/hooks/general';

export const useChannelsBlockData = (onLinkClick?: () => void) => {
  const [isChannelOverviewOpen, setIsChannelOverviewOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    channels,
    dmChannels,
    archivedChannels,
  } = useAppSelector(chatSelectoresV2.selectChannels);

  const channelsMeta = useAppSelector(({ chatPageV2 }) => chatPageV2.channelsMeta);

  const isChannelsLoading = useAppSelector(({ chatPageV2 }) => chatPageV2.isChannelsLoading);
  const getMyChannelsErrorMessage = useAppSelector(({ chatPageV2 }) => {
    return chatPageV2.getMyChannelsError;
  });
  const currentChannel = useCurrentChannel();
  const user = useUser();

  const { t } = useTranslation('errors');

  const sortedDmChannels = React.useMemo(() => {
    return dmChannels.sort((a, b) => {
      const isNotesChannelA = a?.type === ChatTypesENUM.direct && a?.userToChannels?.length === 1;
      const isNotesChannelB = b?.type === ChatTypesENUM.direct && b?.userToChannels?.length === 1;

      if (isNotesChannelA !== isNotesChannelB) {
        return isNotesChannelA ? -1 : 1;
      }

      const metaA = channelsMeta[a.channelId];
      const metaB = channelsMeta[b.channelId];

      const isNotesChannel = a?.type === ChatTypesENUM.direct && a?.userToChannels?.length === 1;

      if (isNotesChannel) {
        return -1;
      }

      if ((metaA.unreadMessagesCount > 0) !== (metaB.unreadMessagesCount > 0)) {
        return metaB.unreadMessagesCount > 0 ? 1 : -1;
      }

      if (metaA.unreadMessagesCount > 0) {
        const aLastMessageDate = metaA?.lastMessageDate ? new Date(metaA?.lastMessageDate) : new Date();
        const bLastMessageDate = metaB?.lastMessageDate ? new Date(metaB?.lastMessageDate) : new Date();
        return bLastMessageDate.getTime() - aLastMessageDate.getTime();
      }

      return 0;
    });
  }, [dmChannels, channelsMeta]);

  React.useEffect(() => {
    if (!getMyChannelsErrorMessage) {
      return;
    }

    toast.error(t('chat.getMyChannels.failed'));
  }, [getMyChannelsErrorMessage, t]);

  const [isArchiveOpen, setIsArchiveOpen] = React.useState(false);

  React.useEffect(() => {
    if (isArchiveOpen && !archivedChannels.length) {
      setIsArchiveOpen(false);
    }
  }, [archivedChannels.length, isArchiveOpen, dispatch]);

  React.useEffect(() => {
    const newValue = Boolean(currentChannel?.isArchived);

    setIsArchiveOpen(newValue);
  }, [currentChannel, dispatch, user.userId]);

  const handleNewChannelClick = () => {
    dispatch(chatSliceActions.setIsShowCreatingChannel(true));
  };

  const handleNewDmClick = () => {
    navigate(ROUTES.chat.createPath('new-dm'));
    onLinkClick?.();
  };

  const toggleArchiveView = () => {
    setIsArchiveOpen((prev) => !prev);
  };

  const toggleChannelOverview = () => {
    setIsChannelOverviewOpen((prev) => !prev);
  };

  const handleUnarchiveallClick = () => {
    dispatch(chatSliceActions.setIsShowAllArchivalChannelsRestore(true));
  };

  const handleDeleteAllArchivedClick = () => {
    dispatch(chatSliceActions.setIsShowArchivalChannelsDelete(true));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChannelClick = (ev: React.MouseEvent, channel: IChannel) => {
    //
  };

  return {
    channels,
    dmChannels: sortedDmChannels,
    archivedChannels,
    isChannelsLoading,
    handleNewChannelClick,
    handleNewDmClick,
    isArchiveOpen,
    isChannelOverviewOpen,
    toggleArchiveView,
    toggleChannelOverview,
    handleUnarchiveallClick,
    handleDeleteAllArchivedClick,
    handleChannelClick,
  };
};
