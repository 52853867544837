import { linkValidation } from '../linkValidation';
import { ElectronActions } from './ElectronActions';
import { ElectronEvents } from './ElectrongEvents';
import { type IElectronAPI } from './types';

class ElectronAPIBuilder {
  private static electronExternalAPI: IElectronAPI | undefined = (window as Window & {
    electronAPI?: IElectronAPI;
  }).electronAPI;

  private static systemStatus: 'normal' | 'suspended' = 'normal';

  // eslint-disable-next-line no-useless-constructor
  constructor(public actions: ElectronActions, public events: ElectronEvents) {
    this.runInitialization();
  }

  public static get electronAPI(): IElectronAPI | undefined {
    return this.electronExternalAPI;
  }

  public get systemStateInNormalMode(): boolean {
    // if its not electron app, we don't care about it and think system in normal mode
    if (!this.isElectronApp) {
      return true;
    }
    return ElectronAPIBuilder.systemStatus === 'normal';
  }

  public get systemStateInSuspendedMode(): boolean {
    // if its not electron app, we don't care about it and think that system can't be suspended
    if (!this.isElectronApp) {
      return false;
    }
    return ElectronAPIBuilder.systemStatus === 'suspended';
  }

  private runInitialization(): void {
    if (!this.isElectronApp) {
      return;
    }
    this.events.onAppSuspend(() => {
      console.info('>>> System is suspended <<<', new Date().toLocaleString());
      ElectronAPIBuilder.systemStatus = 'suspended';
    });
    this.events.onAppResume(() => {
      console.info('>>> System is resumed <<<', new Date().toLocaleString());
      ElectronAPIBuilder.systemStatus = 'normal';
    });
  }

  public get isElectronApp() {
    return ElectronAPIBuilder.electronAPI !== undefined;
  }

  public get isLatest(): boolean {
    return this.isElectronApp && this.getAppVersion?.() === process.env.REACT_APP_DESKTOP_VERSION;
  }

  public get isBeta(): boolean {
    return this.isElectronApp && this.getAppVersion?.().includes('beta');
  }

  public getAppVersion(): string {
    if (!this.isElectronApp) {
      return '';
    }
    return ElectronAPIBuilder.electronAPI?.getAppVersion?.() || '';
  }
}

export const ElectronAPI = new ElectronAPIBuilder(
  new ElectronActions(ElectronAPIBuilder.electronAPI, linkValidation),
  new ElectronEvents(ElectronAPIBuilder.electronAPI),
);
