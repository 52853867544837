import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from './IconButton';
import { ReactComponent as PlusIcon } from 'src/ui/pages/Chat/assets/icons/add.svg';
import { ReactComponent as MoreIcon } from 'src/ui/pages/Chat/assets/icons/more-channel.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/arrow-down-filled.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';

import useMenuAnchor from 'src/utils/hooks/useMenuAnchor';

const ChatListHeader: React.FC<{
  onBackButtonClick?: () => void;
  showBackButton?: boolean;
  title: string;
  onCollapseTogglerClick?: () => void;
  isCollapseOpen?: boolean;
  showCollapseButton?: boolean;
  showMoreButton?: boolean;
  onShowMoreClick?: () => void;
  moreMenuItems?: Array<{
    icon?: React.ReactNode;
    title: React.ReactNode;
    onClick: (data: { onClose: () => void }) => void;
    id: number | string;
    /** Default value - `false` */
    hidden?: boolean;
  }>;
  onPlusIconClick?: () => void;
  plusIconTooltipText?: string;
  showPlusButton?: boolean;
}> = (props) => {
  const moreMenuAnchorData = useMenuAnchor();

  const handleMoreClick: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    props.onShowMoreClick?.();

    if (moreMenuAnchorData.anchor) {
      moreMenuAnchorData.clearAnchor();
    } else {
      moreMenuAnchorData.setAnchor(ev.currentTarget);
    }
  };

  return (
    <StyledChatListHeader>
      <div className="header-content-wrapper">
        {props.showBackButton && (
          <ArrowLeftIcon
            width={20}
            height={20}
            onClick={props.onBackButtonClick}
            className="back-button"
          />
        )}

        <b className="channel-list-title">
          {props.title}
        </b>

        {props.showCollapseButton && (
          <IconButton
            onClick={props.onCollapseTogglerClick}
            variant="hoverable"
            className={classNames('toggler-button', { 'toggler-button--closed': props.isCollapseOpen })}
          >
            <DownArrowIcon width={10} height={10} />
          </IconButton>
        )}
      </div>

      <div className="header-content-wrapper">
        {props.showMoreButton && (
          <>
            <IconButton
              onClick={handleMoreClick}
            >
              <MoreIcon width={10} height={10} className="more-icon" />
            </IconButton>

            <StyledMuiMenu
              anchorEl={moreMenuAnchorData.anchor}
              onClose={moreMenuAnchorData.clearAnchor}
              open={moreMenuAnchorData.isAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {props.moreMenuItems?.map((menuItem) => {
                if (menuItem.hidden) {
                  return null;
                }

                return (
                  <MenuItem
                    key={menuItem.id}
                    onClick={() => menuItem.onClick({ onClose: moreMenuAnchorData.clearAnchor })}
                    className="channels-list-menu-item"
                  >
                    {menuItem.icon}

                    <span>{menuItem.title}</span>
                  </MenuItem>
                );
              })}
            </StyledMuiMenu>
          </>
        )}

        {props.showPlusButton && (
          <Tooltip
            title={props.plusIconTooltipText}
            arrow
            placement="top"
          >
            <IconButton
              onClick={props.onPlusIconClick}
            >
              <PlusIcon width={10} height={10} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </StyledChatListHeader>
  );
};

export const StyledChatListHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .back-button {
    cursor: pointer;
  }

  .channel-list-title {
    color: ${({ theme }) => theme.palette.text.tertiary};
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: ${({ theme }) => theme.font.weight.xlg};
  }

  .toggler-button {
    margin-left: -2px;
  }

  .toggler-button svg {
    transition: 100ms;
  }

  .more-icon * {
    fill: ${({ theme }) => theme.palette.secondary.light};
  }

  .toggler-button--closed svg {
    transform: rotate(-90deg);
  }

  .header-content-wrapper {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;

export const StyledMuiMenu = styled(Menu)`
  .MuiPaper-root.MuiPaper-root {
    background: ${({ theme }) => theme.palette.background.paper};
    border: 1px solid ${({ theme }) => theme.palette.menu.border};
    border-radius: 12px;
    box-shadow: none;
    padding: 0;
    margin-left: 27px;
    margin-top: 5px;
  }

  .MuiList-root {
    border: none;
    padding: 6px 0;
    margin-top: 0;
    border-radius: 0;
  }

  .MuiMenuItem-root {
    padding: 10px 16px;
    font-size: ${({ theme }) => theme.font.size.sm};
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    transition: 100ms;

    :hover {
      background-color: ${({ theme }) => theme.palette.primary[100]};
    }
  }

  .channels-list-menu-item {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .unarchive-icon {
    transform: rotate(-90deg);

    * {
      fill: ${({ theme }) => theme.palette.secondary.light};
    }
  }
`;

export default ChatListHeader;
