import type { ResponseType } from 'src/api/http';
import socket from 'src/api/ws/socket';
import { SocketEventsENUM } from 'src/api/ws/socketEvents';
import type { IChannelVisiblePayload, IMessage, IUser, ReactionType, UserToThreadType } from 'src/types';
import type { SpecialMentionTypeENUM } from '../types/quillTypes';

const getChannelMesages = (params: {
  channelId: number;
  unreadMessagesCount?: number;
  limit?: number;
  upAnchor?: string | Date;
  downAnchor?: string | Date;
  messageId?: number;
}) => {
  return socket.emit<IMessage[], {
    hasMoreUp?: boolean;
    hasMoreDown?: boolean;
    firstUnreadMessageId?: number | null;
  }>(SocketEventsENUM.chat_getChannelMessages, params);
};

const getThreadMesages = (params: {
  parentMessageId: number;
}) => {
  return socket.emit<IMessage[], {
    firstUnreadMessageId?: number | null;
    threadUsers: UserToThreadType[];
  }>(SocketEventsENUM.chat_getThreadMessages, params);
};

export type NewMessageMetaType = {
  type: 'thread' | 'channel';
  channelId: number;
  parentMessageId?: number;
  referencedMessageId?: number;
  userAddedToThreadIds: (number | SpecialMentionTypeENUM)[];
  userMentionUserIds: (number | SpecialMentionTypeENUM)[];
};

const subscribeOnNewMessage = (handler: (response: ResponseType<IMessage, NewMessageMetaType>) => void) => {
  return socket.addEventHandler<IMessage, NewMessageMetaType>(SocketEventsENUM.chat_newMessage, handler);
};

const subscribeOnVisibleChannel = (handler: (response: ResponseType<IChannelVisiblePayload>) => void) => {
  return socket.addEventHandler(SocketEventsENUM.chat_channelBecomeVisible, handler);
};

type ChangedOwerDataType = {
  channelId: number;
  newOwner: IUser;
};
const subscribeOnOldChannelOwnerReassigned = (handler: (response: ChangedOwerDataType) => void) => {
  return socket.addOldEventHandler<ChangedOwerDataType>(SocketEventsENUM.ownerReassigned, handler);
};

type ReadUpdateDataType = {
  channelId: number;
  parentMessageId?: number;
  lastViewedMessageTime: string;
};
const subscribeOnReadStatusChange = (handler: (response: ResponseType<ReadUpdateDataType>) => void) => {
  return socket.addEventHandler<ReadUpdateDataType>(SocketEventsENUM.chat_messagesHasBeenRead, handler);
};

const sendMessage = (data: {
  channelId: number;
  forwardedMessageId?: number;
  mediaItemsIds: number[];
  text: string;
  parentMessageId?: number | null;
  referenceMessageId?: number | null;
  mentionUsersIds?: (number | SpecialMentionTypeENUM)[];
  previewLinks?: string[];
  shouldSetVisible?: boolean;
}) => {
  return socket.emit<IMessage, NewMessageMetaType>(SocketEventsENUM.chat_createNewMessage, data);
};

type ReactionBodyType = {
  reaction: ReactionType;
  actionType: 'add' | 'remove';
};
type ReactionMetaType = {
  channelId: number;
  parentId: number | null;
  messageId: number;
};

const toggleReaction = (data: {
  messageId: number;
  shortcode: string;
  modificator: string | null;
  emoji: string;
}) => {
  return socket.emit<ReactionBodyType, ReactionMetaType>(SocketEventsENUM.chat_toggleReaction, data);
};

const subscribeOnReaction = (handler: (response: ResponseType<ReactionBodyType, ReactionMetaType>) => void) => {
  return socket.addEventHandler<ReactionBodyType, ReactionMetaType>(SocketEventsENUM.chat_reactionUpdated, handler);
};

export default {
  getChannelMesages,
  getThreadMesages,
  subscribeOnNewMessage,
  subscribeOnOldChannelOwnerReassigned,
  subscribeOnReadStatusChange,
  sendMessage,
  toggleReaction,
  subscribeOnReaction,
  subscribeOnVisibleChannel,
};
