import React from 'react';

import Typography from '@mui/material/Typography';
import UserListItem from 'src/ui/pages/Chat/components/CustomMultiSelect/UserListItem';
import MoreButton from './MoreButton';
import StyledResultsCategory from './ResultsCategory.styles';

import type { IUser } from 'src/types';
import { SearchResultsCategoryENUM } from 'src/types/chatTypes';
import { t } from 'src/utils/getTranslation';
import { useOpenDm } from '../../Chat.hooks';

const UserResults: React.FC<{
  userResults: IUser[];
  value: string;
  searchResult?: string;
  handleClose: () => void;
  isVisibleMoreButton: boolean;
}> = (props) => {
  const dm = useOpenDm(props.handleClose);

  const handleClickUser = (userId: number) => {
    dm.openDm(userId, { clearLoaderOnEnd: true });
  };

  return (
    <StyledResultsCategory>
      {dm.loaderNode}

      <Typography
        variant="h4"
        className="results__title"
      >
        {t('chat:navigation.search.workers.title')}
      </Typography>
      {props.userResults.length
        ? (
          <>
            {props.userResults.map((user) => (
              <UserListItem
                user={user}
                key={user.userId}
                size="small"
                handleClickListItem={() => handleClickUser(user.userId)}
                searchResult={props.searchResult}
                className="results__user"
              />
            ))}
          </>
        )
        : (
          <Typography className="results__empty-state">
            {t('chat:searchResults.notFound.noResults')}
          </Typography>
        )}

      {props.isVisibleMoreButton && (
        <MoreButton
          text={t('chat:navigation.search.workers.showButton')}
          value={props.value}
          category={SearchResultsCategoryENUM.members}
          handleClose={props.handleClose}
        />
      )}
    </StyledResultsCategory>
  );
};

export default UserResults;
