import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';

type StyledUsersListItemPropsType = {
  isSelected: boolean;
  size: 'small' | 'middle';
  isSearch?: boolean;
};

const StyledUsersListItem = styled(MenuItem) <StyledUsersListItemPropsType>`
  padding: ${({ size }) => (size === 'small' ? '6px 16px' : '10px 20px')};
  padding-left: ${({ isSearch }) => (isSearch ? '4px' : undefined)};
  padding-right: ${({ isSearch }) => (isSearch ? '0px' : '16px')};
  display: flex;
  gap: 8px;
  background-color: ${({ isSelected, isSearch, theme }) => {
    if (isSearch) {
      return theme.palette.background.paper;
    }
    if (isSelected) {
      return theme.palette.select.optionBackground.secondary;
    }
    return theme.palette.select.optionBackground.primary;
  }};
  border-radius: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: ${({ size }) => (size === 'small' ? '32px' : 'none')};

  .message-item__message-text {
    font-weight: ${({ theme }) => theme.font.weight.lg};
    font-size: ${({ theme }) => theme.font.size.xs};
    max-width: 88%;
  }

  .list-item__job-title, .list-item__in-channel {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: ${({ theme, size }) => (size === 'small' ? theme.font.size.xs : theme.font.size.sm)};
  }

  .list-item__job-title {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .list-item__name {
    color: ${({ theme }) => theme.palette.grey[50]};
    font-weight: ${({ theme }) => theme.font.weight.lg};
    font-size: ${({ theme, size }) => (size === 'small' ? theme.font.size.xs : theme.font.size.sm)};
  }

  .list-item__job-title {
    color: ${({ theme }) => theme.palette.grey[200]};
    font-weight: ${({ theme }) => theme.font.weight.sm};
    font-size: ${({ theme }) => theme.font.size.xs};
  }

  .list-item__avatar {
    font-size: ${({ theme }) => theme.font.size.xs};
    width: ${({ size }) => (size === 'small' ? '20px' : '40px')};
    height: ${({ size }) => (size === 'small' ? '20px' : '40px')};
  }
`;

export default StyledUsersListItem;
