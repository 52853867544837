import { combineReducers } from 'redux';

import mainSlice from 'src/store/mainSlice';
import employeesPageSlice from 'src/ui/pages/hrm/store';
import chatPageSlice from 'src/ui/pages/Chat/store';
import requestsPageSlice from 'src/legacy/pages/ListOfRequests/store';
import extraHoursPageSlice from 'src/legacy/pages/ExtraHours/store';
import inventoryPageSlice from 'src/legacy/pages/Inventory/store';
import accountPageSlice from 'src/legacy/pages/Account/store';
import chatSliceV2 from 'src/ui/pages/Chat/storeV2/chatSliceV2';
import { jobPositionsQuery } from 'src/api/rtkQuery/jobPositionsQuery';
import { departmentsQuery } from 'src/api/rtkQuery/departmentsQuery';
import { usersQuery } from 'src/api/rtkQuery/usersQuery';
import workspacesMetaReducer from './workspacesMeta/workspacesMeta';

const legacyCombinedReducer = combineReducers({
  requestsPage: requestsPageSlice,
  extra: extraHoursPageSlice,
  inventory: inventoryPageSlice,
  userAccount: accountPageSlice,
});

const combinedReducer = combineReducers({
  legacy: legacyCombinedReducer,
  main: mainSlice,
  employeesPage: employeesPageSlice,
  chatPage: chatPageSlice,
  chatPageV2: chatSliceV2.reducer,
  workspacesMeta: workspacesMetaReducer,
  [departmentsQuery.reducerPath]: departmentsQuery.reducer,
  [jobPositionsQuery.reducerPath]: jobPositionsQuery.reducer,
  [usersQuery.reducerPath]: usersQuery.reducer,
});

export const RESET_STORE_ACTION_TYPE = 'RESET_WHOLE_STORE';

const rootReducer = (
  state: ReturnType<typeof combinedReducer> | undefined,
  action: { type: string; payload: unknown },
) => {
  if (action.type === RESET_STORE_ACTION_TYPE) {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export default rootReducer;
